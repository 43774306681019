import React, {useEffect} from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Signin from "../components/auth/signin"
import { hasAuthToken } from '../actions/_auth'

const AUTH_LANDING = '/app'

const IndexPage = () => {
  useEffect(() => {
    if(hasAuthToken() === true) {
      const windowGlobal = typeof window !== 'undefined' && window
      windowGlobal.location = AUTH_LANDING
    }
  }, [])

  if(hasAuthToken() === false) {
    return(
      <Layout>
        <SEO title="Dashboard" />
        <Signin />
      </Layout>
    )
  } else {
    return <span>loading...</span>
  }
}

export default IndexPage
